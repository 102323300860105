import styled from 'styled-components';
import { Link as LinkRD } from 'react-router-dom';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 32px;
  gap: 0.5rem;
`;
Body.displayName = 'Body';

export const Container = styled.article`
  background-color: rgb(18, 18, 18);
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 465px;
`;
Container.displayName = 'Container';

export const Description = styled.p`
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
`;
Description.displayName = 'Description';

export const Figure = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;
Figure.displayName = 'Figure';

export const Image = styled.img`
  aspect-ratio: 16 / 9;
  border-radius: 16px;
  color: transparent;
  display: block;
  height: auto;
  margin: 0;
  max-width: 100%;
  object-position: center center;
  object-fit: cover;
  vertical-align: middle;
`;
Image.displayName = 'Image';

export const Link = styled(LinkRD)`
  color: #675ce7;
  cursor: pointer;
  text-decoration-line: none;

  &:hover {
    text-decoration-line: underline;
  }
`;
Link.displayName = 'Link';

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 0;
`;
Title.displayName = 'Title';
