import React from 'react';
import i18n from 'i18n-js';

import InstagramSvg from '../../assets/icons/instagram.svg';
import TiktokSvg from '../../assets/icons/tiktok.svg';
import TwitterSvg from '../../assets/icons/twitter.svg';
import LogoSvg from '../../assets/icons/Imagotipo.svg';

import { Contact, Container, Copyright, Legal, LinkItem, Social } from './styles';

function Footer() {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <Container>
      <Legal>
        <LinkItem to="/blog">{i18n.t('screens.home.footer.blog')}</LinkItem>
        <LinkItem to="/terms-and-conditions">{i18n.t('screens.home.footer.terms')}</LinkItem>
        <LinkItem to="/privacy">{i18n.t('screens.home.footer.privacy')}</LinkItem>
        <LinkItem to="/cookies">{i18n.t('screens.home.footer.cookies')}</LinkItem>
      </Legal>
      <Social>
        <Contact href="https://twitter.com/overlayon" target="_blank">
          <img src={TwitterSvg} alt="Twitter" />
        </Contact>
        <Contact href="https://www.instagram.com/overlayon" target="_blank">
          <img src={InstagramSvg} alt="Instagram" />
        </Contact>
        <Contact href="https://www.tiktok.com/@overlayon" target="_blank">
          <img src={TiktokSvg} alt="Tiktok" />
        </Contact>
      </Social>
      <img src={LogoSvg} alt="Overlay On" />
      <Copyright>{`© ${year} Overlay On`}</Copyright>
      <Copyright>
        {i18n.t('screens.home.footer.createdBy')}{' '}
        <a alt="Apoya este proyecto" href="https://www.buymeacoffee.com/overlayon" rel="noreferrer" target="_blank">
          {i18n.t('screens.home.footer.backMeUp')}
        </a>
      </Copyright>
    </Container>
  );
}

export default Footer;
