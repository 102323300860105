import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AnalyticsProvider } from '../../providers';
import {
  Footer,
  Header,
  HowItWorks,
  Main,
  Monetization,
  Overlays,
  StreamWherever,
} from '../../components';

function Home() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const referral = params.get('referral');
    if (referral) {
      localStorage.setItem('referral', referral);
    }
  }, [search]);

  useEffect(() => {
    AnalyticsProvider.hit('/landing');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <Main />
      <Monetization />
      <Overlays />
      <HowItWorks />
      <StreamWherever />
      <Footer />
    </>
  );
}

export default Home;
