import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import i18n from 'i18n-js';

import locales from './constants/locales';
import AnalyticsProvider from './providers/analytics';
import { Article, Blog, Cookies, Home, Privacy, TermsAndConditions } from './screens';

i18n.locale = navigator.language || navigator.userLanguage;
i18n.defaultLocale = 'en';
i18n.fallbacks = true;
i18n.translations = locales;

const GlobalStyle = createGlobalStyle`
  body {
    background:
      radial-gradient(farthest-side,rgba(72, 64, 164, 0.4) 1%,transparent) calc(100% + 600px) 444px/1280px 1280px,
      radial-gradient(farthest-side,rgba(72, 64, 164, 0.4) 1%,transparent) -600px calc(100% + 300px)/1280px 1280px,
      #060606;
    background-color: #060606;
    background-repeat:no-repeat;
    font-family: 'Noto Sans', sans-serif;
    overflow-x: hidden;
  }
`;

function App() {
  useEffect(() => {
    AnalyticsProvider.initialize();
  }, []);

  return (
    <>
      <Helmet>
        <title>OverlayOn</title>
        <meta name="description" content={i18n.t('screens.home.main.titleMeta')} />
      </Helmet>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route path="/cookies">
            <Cookies />
          </Route>
          <Route path="/blog/:articleId">
            <Article />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
