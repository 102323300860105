import styled from 'styled-components';

import LogoImg from '../../assets/images/union.webp';
import { LAYOUT } from '../../constants';

const { MOBILE_RESOLUTION } = LAYOUT;

export const Container = styled.section`
  align-items: center;
  background-image: url('${LogoImg}');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 105px;
  display: flex;
  flex-direction: column;
  padding: 120px 24px;

  ${MOBILE_RESOLUTION} {
    padding: 80px 24px;
  }
`;
Container.displayName = 'Container';

export const Description = styled.p`
  color: #8894a4;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.02em;
  line-height: 24px;
  margin: 24px 0px;
  text-align: center;
  max-width: 647px;

  ${MOBILE_RESOLUTION} {
    min-width: 312px;
  }

  & strong {
    color: #675ce7;
  }
`;
Description.displayName = 'Description';

export const SignUp = styled.button`
  align-items: center;
  background: #675ce7;
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(6, 6, 6, 0.25);
  color: #f8f7ff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 56px;
  justify-content: center;
  letter-spacing: 0.04em;
  line-height: 24px;
  margin: 48px 0 0;
  text-decoration: none;
  width: 199px;

  ${MOBILE_RESOLUTION} {
    width: 312px;
  }
`;
SignUp.displayName = 'SignUp';

export const Title = styled.h1`
  color: #f8f7ff;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  letter-spacing: 0.04em;
  line-height: 80px;
  margin: 0px;
  text-align: center;
  text-transform: uppercase;
  max-width: 647px;

  ${MOBILE_RESOLUTION} {
    min-width: 312px;
  }

  & strong {
    color: #675ce7;
  }
`;
Title.displayName = 'Title';
