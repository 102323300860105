import styled from 'styled-components';

export const ArticlesSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  max-width: 1200px;
`;
ArticlesSection.displayName = 'ArticlesSection';

export const ArticlesWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
ArticlesWrapper.displayName = 'ArticlesWrapper';

export const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
Section.displayName = 'Section';

export const Subtitle = styled.p`
  color: #f5f5f5;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
`;
Subtitle.displayName = 'Subtitle';

export const Title = styled.h1`
  color: #675ce7;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 48px;
  margin: 0;
  margin-bottom: 24px;
`;
Title.displayName = 'Title';

export const TitleSection = styled.div`
  margin-bottom: 8rem;
  margin-top: 3rem;
  max-width: 36rem;
  text-align: center;
`;
TitleSection.displayName = 'TitleSection';

export const Wrapper = styled.div`
  padding: 2rem;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';
