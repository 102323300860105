import styled from 'styled-components';

export const Container = styled.section`
  margin: 0 16px;
  max-width: 600px;
`;
Container.displayName = 'Container';

export const Subtitle = styled.h3`
  color: #675ce7;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
`;
Subtitle.displayName = 'Subtitle';

export const Text = styled.p`
  color: #a7a9be;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.011em;
  line-height: 24px;
  margin: 24px 0;
  max-width: 959px;
  text-align: justify;
`;
Text.displayName = 'Text';

export const Title = styled.h2`
  color: #675ce7;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
`;
Title.displayName = 'Title';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 120px 0 80px;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';
