import { C } from '../constants';

const { APP_URL } = C;

function getAppUrl(path) {
  const referral = localStorage.getItem('referral');
  if (referral) {
    return `${APP_URL}/${path}?referral=${referral}`;
  }
  return `${APP_URL}/${path}`;
}

export default getAppUrl;
