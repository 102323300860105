import React from 'react';
import i18n from 'i18n-js';

import facebookGamingImg from '../../assets/icons/facebook-gaming-logo.svg';
import obsStudioImg from '../../assets/icons/OBS_Studio_Logo.svg';
import streamlabsImg from '../../assets/icons/Streamlabs_logo.svg';
import twitchImg from '../../assets/icons/twitch-seeklogo.com.svg';
import xsplitImg from '../../assets/icons/xsplit-logo-vector.svg';
import youtubeImg from '../../assets/icons/YouTube_Logo_2017.svg';

import {
  Container,
  Content,
  ContentDescription,
  ContentTitle,
  Logos,
} from './styles';

function StreamWherever() {
  return (
    <Container id="stream-wherever">
      <Content>
        <ContentTitle>{i18n.t('screens.home.streamWherever.title')}</ContentTitle>
        <ContentDescription>
          {i18n.t('screens.home.streamWherever.description')}
        </ContentDescription>
      </Content>
      <Logos>
        <img src={twitchImg} alt="Twitch" />
        <img src={youtubeImg} alt="Youtube gaming" />
        <img src={facebookGamingImg} alt="Facebook gaming" />
        <img src={obsStudioImg} alt="OBS" />
        <img src={streamlabsImg} alt="Streamlabs" />
        <img src={xsplitImg} alt="XSplit" />
      </Logos>
    </Container>
  );
}

export default StreamWherever;
