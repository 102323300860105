import { Body, Container, Description, Figure, Image, Link, Title } from './styles';

const CardArticle = ({ article }) => {
  return (
    <Container>
      {article.image?.src && (
        <Link to={`/blog/${article.slug}`} title={article.title} rel="bookmark">
          <Figure>
            <Image
              src={article.image.src}
              alt={article.image.alt}
              width={600}
              height={338}
              placeholder="blur"
            />
          </Figure>
        </Link>
      )}
      <Body>
        <Title>
          <Link
            to={`/blog/${article.slug}`}
            className="link link-hover hover:link-primary"
            title={article.title}
            rel="bookmark"
          >
            {article.title}
          </Link>
        </Title>
        <Description>{article.description}</Description>
      </Body>
    </Container>
  );
};

export default CardArticle;
