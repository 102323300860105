import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';

export const events = {
  CLICK_SIGNUP: {
    action: 'CLICK_SIGNUP',
    category: 'LANDING',
  },
  CLICK_TRYNOW_HEADER: {
    action: 'CLICK_TRYNOW_HEADER',
    category: 'LANDING',
  },
  EXPLORE_ALL_OVERLAYS: {
    action: 'EXPLORE_ALL_OVERLAYS',
    category: 'LANDING',
  },
  EARNING_NOW: {
    action: 'EARNING_NOW',
    category: 'LANDING',
  },
};

let isInitialized = false;

export function initialize() {
  if (process.env.NODE_ENV === 'development') return;

  ReactGA.initialize(process.env.REACT_APP_ANALYTICS);
  mixpanel.init(process.env.REACT_APP_MIXPANEL);
  isInitialized = true;
}

export function hit(route) {
  if (process.env.NODE_ENV === 'development' || !isInitialized) return;

  ReactGA.pageview(route);
  mixpanel.track(route);
}

export function event(eventName, value = {}) {
  if (process.env.NODE_ENV === 'development' || !isInitialized) return;

  const { action, category } = events[eventName] || {};
  ReactGA.event({ category, action, ...value });
  mixpanel.track(action, { category, ...value });
}

const data = {
  event,
  events,
  hit,
  initialize,
};

export default data;
