import styled from 'styled-components';

import { LAYOUT } from '../../constants';

const { MOBILE_RESOLUTION } = LAYOUT;

export const Container = styled.section`
  align-items: center;
  background: #1c1d1f;
  display: flex;
  flex-direction: column;
  padding: 120px 24px;

  ${MOBILE_RESOLUTION} {
    padding: 80px 24px;
  }
`;
Container.displayName = 'Container';

export const Description = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;

  ${MOBILE_RESOLUTION} {
    flex-direction: column;
  }
`;
Description.displayName = 'Description';

export const Details = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: 64px;
  max-width: 312px;

  &:last-child {
    margin-right: 0;
  }

  ${MOBILE_RESOLUTION} {
    margin-bottom: 64px;
    margin-right: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
Details.displayName = 'Details';

export const DetailsDescription = styled.p`
  color: #8894a4;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.02em;
  line-height: 24px;
  margin: 0;
`;
DetailsDescription.displayName = 'DetailsDescription';

export const DetailsTitle = styled.p`
  color: #f8f7ff;
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 48px;
  margin: 24px 0;
  min-height: 95px;

  ${MOBILE_RESOLUTION} {
    min-height: auto;
  }
`;
DetailsTitle.displayName = 'DetailsTitle';

export const EarnMoreButton = styled.button`
  align-items: center;
  background: #675ce7;
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(6, 6, 6, 0.25);
  color: #f8f7ff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 56px;
  justify-content: center;
  letter-spacing: 0.04em;
  line-height: 24px;
  margin: 64px 0 0;
  text-decoration: none;
  width: 210px;

  ${MOBILE_RESOLUTION} {
    width: 312px;
  }
`;
EarnMoreButton.displayName = 'EarnMoreButton';

export const Title = styled.h2`
  color: #f8f7ff;
  font-size: 48px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 64px;
  margin: 0 0 64px;
  text-align: center;
`;
Title.displayName = 'Title';
