import React from 'react';
import i18n from 'i18n-js';

import ArrowSvg from '../../assets/icons/arrow_right_alt.svg';
import CountdownSvg from '../../assets/icons/hourglass_top.svg';
import ControllerSvg from '../../assets/icons/sports_esports.svg';
import RouletteSvg from '../../assets/icons/rotate_90_degrees_ccw.svg';
import ScoreboardSvg from '../../assets/icons/sports_soccer.svg';

import { AnalyticsProvider } from '../../providers';
import { getAppUrl } from '../../helpers';

import {
  Card,
  Column,
  ColumnCta,
  Container,
  Content,
  ContentTitle,
  CtaLink,
  Description,
  OverlaysContainer,
  Title,
} from './styles';

function Overlays() {
  const onClickSignup = () => {
    const url = getAppUrl('dashboard');
    window.open(url, 'blank');
    AnalyticsProvider.event('EXPLORE_ALL_OVERLAYS');
  };

  return (
    <Container id="overlays">
      <Content>
        <ContentTitle>{i18n.t('screens.home.overlays.title')}</ContentTitle>
        <OverlaysContainer>
          <Column>
            <Card first>
              <img src={ScoreboardSvg} alt={i18n.t('screens.home.overlays.title4')} />
              <Title>{i18n.t('screens.home.overlays.title4')}</Title>
              <Description>
                {i18n.t('screens.home.overlays.description41')}
                <strong>{i18n.t('screens.home.overlays.description42')}</strong>
              </Description>
            </Card>
            <Card>
              <img src={CountdownSvg} alt={i18n.t('screens.home.overlays.title1')} />
              <Title>{i18n.t('screens.home.overlays.title1')}</Title>
              <Description>
                {i18n.t('screens.home.overlays.description11')}
                <strong>{i18n.t('screens.home.overlays.description12')}</strong>
                {i18n.t('screens.home.overlays.description13')}
                <strong>{i18n.t('screens.home.overlays.description14')}</strong>
              </Description>
            </Card>
          </Column>
          <Column>
            <Card first>
              <img src={ControllerSvg} alt={i18n.t('screens.home.overlays.title2')} />
              <Title>{i18n.t('screens.home.overlays.title2')}</Title>
              <Description>
                {i18n.t('screens.home.overlays.description21')}
                <strong>{i18n.t('screens.home.overlays.description22')}</strong>
                {i18n.t('screens.home.overlays.description23')}
              </Description>
            </Card>
            <Card>
              <img src={RouletteSvg} alt={i18n.t('screens.home.overlays.title3')} />
              <Title>{i18n.t('screens.home.overlays.title3')}</Title>
              <Description>
                {i18n.t('screens.home.overlays.description31')}
                <strong>{i18n.t('screens.home.overlays.description32')}</strong>
              </Description>
            </Card>
          </Column>
          <ColumnCta>
            <CtaLink onClick={onClickSignup}>
              {i18n.t('screens.home.overlays.cta')}
              <img src={ArrowSvg} alt="Arrow" />
            </CtaLink>
          </ColumnCta>
        </OverlaysContainer>
      </Content>
    </Container>
  );
}

export default Overlays;
