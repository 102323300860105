import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AnalyticsProvider } from '../../providers';
import { Footer, Header } from '../../components';

import { Container, Subtitle, Text, Title, Wrapper } from './styles';

function Cookies() {
  const { pathname } = useLocation();

  useEffect(() => {
    AnalyticsProvider.hit('/cookies');
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <Wrapper>
        <Container>
          <Title>POLÍTICA DE COOKIES</Title>
          <Text>
            WWW.OVERLAYON.COM informa acerca del uso de las cookies en su
            página web: WWW.OVERLAYON.COM
          </Text>
          <Subtitle>¿Qué son las cookies?</Subtitle>
          <Text>
            Las cookies son archivos que se pueden descargar en su equipo a
            través de las páginas web. Son herramientas que tienen un papel
            esencial para la prestación de numerosos servicios de la sociedad de
            la información. Entre otros, permiten a una página web almacenar y
            recuperar información sobre los hábitos de navegación de un usuario
            o de su equipo y, dependiendo de la información obtenida, se pueden
            utilizar para reconocer al usuario y mejorar el servicio ofrecido.
          </Text>
          <Subtitle>Tipos de cookies</Subtitle>
          <Text>
            Según quien sea la entidad que gestione el dominio desde donde se
            envían las cookies y trate los datos que se obtengan se pueden
            distinguir dos tipos:
            <ul>
              <li>
                Cookies propias: aquéllas que se envían al equipo terminal del
                usuario desde un equipo o dominio gestionado por el propio
                editor y desde el que se presta el servicio solicitado por el
                usuario.
              </li>
              <li>
                Cookies de terceros: aquéllas que se envían al equipo terminal
                del usuario desde un equipo o dominio que no es gestionado por
                el editor, sino por otra entidad que trata los datos obtenidos
                través de las cookies.
              </li>
            </ul>
          </Text>
          <Text>
            En el caso de que las cookies sean instaladas desde un equipo o
            dominio gestionado por el propio editor, pero la información que se
            recoja mediante éstas sea gestionada por un tercero, no pueden ser
            consideradas como cookies propias.
          </Text>
          <Text>
            Existe también una segunda clasificación según el plazo de tiempo
            que permanecen almacenadas en el navegador del cliente, pudiendo
            tratarse de:
            <ul>
              <li>
                Cookies de sesión: diseñadas para recabar y almacenar datos
                mientras el usuario accede a una página web. Se suelen emplear
                para almacenar información que solo interesa conservar para la
                prestación del servicio solicitado por el usuario en una sola
                ocasión (p.e. una lista de productos adquiridos).
              </li>
              <li>
                Cookies persistentes: los datos siguen almacenados en el
                terminal y pueden ser accedidos y tratados durante un periodo
                definido por el responsable de la cookie, y que puede ir de unos
                minutos a varios años.
              </li>
            </ul>
          </Text>
          <Text>
            Por último, existe otra clasificación con cinco tipos de cookies
            según la finalidad para la que se traten los datos obtenidos:
            <ul>
              <li>
                Cookies técnicas: aquellas que permiten al usuario la navegación
                a través de una página web, plataforma o aplicación y la
                utilización de las diferentes opciones o servicios que en ella
                existan como, por ejemplo, controlar el tráfico y la
                comunicación de datos, identificar la sesión, acceder a partes
                de acceso restringido, recordar los elementos que integran un
                pedido, realizar el proceso de compra de un pedido, realizar la
                solicitud de inscripción o participación en un evento, utilizar
                elementos de seguridad durante la navegación, almacenar
                contenidos para la difusión de vídeos o sonido o compartir
                contenidos a través de redes sociales.
              </li>
              <li>
                Cookies de personalización: permiten al usuario acceder al
                servicio con algunas características de carácter general
                predefinidas en función de una serie de criterios en el terminal
                del usuario como por ejemplo serian el idioma, el tipo de
                navegador a través del cual accede al servicio, la configuración
                regional desde donde accede al servicio, etc.
              </li>
              <li>
                Cookies de análisis: permiten al responsable de estas, el
                seguimiento y análisis del comportamiento de los usuarios de los
                sitios web a los que están vinculadas. La información recogida
                mediante este tipo de cookies se utiliza en la medición de la
                actividad de los sitios web, aplicación o plataforma y para la
                elaboración de perfiles de navegación de los usuarios de dichos
                sitios, aplicaciones y plataformas, con el fin de introducir
                mejoras en función del análisis de los datos de uso que hacen
                los usuarios del servicio.
              </li>
              <li>
                Cookies publicitarias: permiten la gestión, de la forma más
                eficaz posible, de los espacios publicitarios.
              </li>
              <li>
                Cookies de publicidad comportamental: almacenan información del
                comportamiento de los usuarios obtenida a través de la
                observación continuada de sus hábitos de navegación, lo que
                permite desarrollar un perfil específico para mostrar publicidad
                en función del mismo.
              </li>
              <li>
                Cookies de redes sociales externas: se utilizan para que los
                visitantes puedan interactuar con el contenido de diferentes
                plataformas sociales (facebook, youtube, twitter, linkedIn,
                etc..) y que se generen únicamente para los usuarios de dichas
                redes sociales. Las condiciones de utilización de estas cookies
                y la información recopilada se regulan por la política de
                privacidad de la plataforma social correspondiente.
              </li>
            </ul>
          </Text>
          <Subtitle>Desactivación y eliminación de cookies</Subtitle>
          <Text>
            Tienes la opción de permitir, bloquear o eliminar las cookies
            instaladas en tu equipo mediante la configuración de las opciones
            del navegador instalado en su equipo. Al desactivar cookies, algunos
            de los servicios disponibles podrían dejar de estar operativos. La
            forma de deshabilitar las cookies es diferente para cada navegador,
            pero normalmente puede hacerse desde el menú Herramientas u
            Opciones. También puede consultarse el menú de Ayuda del navegador
            dónde puedes encontrar instrucciones. El usuario podrá en cualquier
            momento elegir qué cookies quiere que funcionen en este sitio web.
          </Text>
          <Text>
            Puede usted permitir, bloquear o eliminar las cookies instaladas en
            su equipo mediante la configuración de las opciones del navegador
            instalado en su ordenador:
            <ul>
              <li>
                Microsoft Internet Explorer o Microsoft Edge:
                <br />
                http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies
              </li>
              <li>
                Mozilla Firefox:
                <br />
                http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia
              </li>
              <li>
                Chrome:
                <br />
                https://support.google.com/-accounts/answer/61416?hl=es
              </li>
              <li>
                Safari:
                <br />
                http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/
              </li>
              <li>
                Opera:
                <br />
                http://help.opera.com/Linux/10.60/es-ES/cookies.html
              </li>
            </ul>
          </Text>
          <Text>
            Además, también puede gestionar el almacén de cookies en su
            navegador a través de herramientas como las siguientes:
            <ul>
              <li>
                Ghostery:
                <br />
                www.ghostery.com/
              </li>
              <li>
                Your online choices:
                <br />
                www.youronlinechoices.com/es/
              </li>
            </ul>
          </Text>
          <Subtitle>Cookies utilizadas en WWW.OVERLAYON.COM</Subtitle>
          <Text>
            A continuación, se identifican las cookies que están siendo
            utilizadas en este portal, así como su tipología y función:
          </Text>
          <Text>
            Cookies analíticas: cada vez que un Usuario visita un servicio, una
            herramienta de un proveedor externo genera una Cookie analítica en
            el ordenador del usuario. Esta Cookie que sólo se genera en la
            visita, servirá en próximas visitas a los Servicios de la Web para
            identificar de forma anónima al visitante. Los objetivos principales
            que se persiguen son:
            <ul>
              <li>
                Permitir la identificación anónima de los usuarios navegantes a
                través de la “Cookie” (identifica navegadores y dispositivos, no
                personas) y por lo tanto la contabilización aproximada del
                número de visitantes y su tendencia en el tiempo.
              </li>
              <li>
                Identificar de forma anónima los contenidos más visitados y por
                lo tanto más atractivos para los usuarios.
              </li>
              <li>
                Saber si el usuario que está accediendo es nuevo o repite
                visita.
              </li>
            </ul>
          </Text>
          <Text>
            Cookies de registro: las Cookies de registro se generan una vez que
            el usuario se ha registrado o posteriormente ha abierto su sesión, y
            se utilizan para identificarle en los servicios con los siguientes
            objetivos:
            <ul>
              <li>
                Mantener al usuario identificado de forma que, si cierra un
                servicio, el navegador o el ordenador y en otro momento u otro
                día vuelve a entrar en dicho servicio, seguirá identificado,
                facilitando así su navegación sin tener que volver a
                identificarse. Esta funcionalidad se puede suprimir si el
                usuario pulsa la funcionalidad “cerrar sesión”, de forma que
                esta Cookie se elimina y la próxima vez que entre en el servicio
                el usuario tendrá que iniciar sesión para estar identificado.
              </li>
              <li>
                Comprobar si el usuario está autorizado para acceder a ciertos
                servicios, por ejemplo, para participar en un concurso.
              </li>
            </ul>
          </Text>
          <Subtitle>Aceptación de la Política de cookies</Subtitle>
          <Text>
            WWW.OVERLAYON.COM asume que usted acepta el uso de cookies.
            No obstante, muestra información sobre su Política de cookies en la
            parte inferior o superior de cualquier página del portal con cada
            inicio de sesión con el objeto de que usted sea consciente.
          </Text>
          <Text>
            Ante esta información es posible llevar a cabo las siguientes
            acciones:
            <ul>
              <li>
                Aceptar cookies. No se volverá a visualizar este aviso al
                acceder a cualquier página del portal durante la presente
                sesión.
              </li>
              <li>Cerrar. Se oculta el aviso en la presente página.</li>
              <li>
                Modificar su configuración. Podrá obtener más información sobre
                qué son las cookies, conocer la Política de cookies de
                WWW.OVERLAYON.COM y modificar la configuración de su
                navegador.
              </li>
            </ul>
          </Text>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

export default Cookies;
