import styled from 'styled-components';

import { LAYOUT } from '../../constants';

const { MOBILE_RESOLUTION } = LAYOUT;

export const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 120px 0px;

  ${MOBILE_RESOLUTION} {
    padding: 80px 24px;
  }
`;
Container.displayName = 'Container';

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;
Content.displayName = 'Content';

export const ContentDescription = styled.p`
  color: #8894a4;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.02em;
  line-height: 24px;
  margin: 0;
  text-align: center;
  width: 100%;
`;
ContentDescription.displayName = 'ContentDescription';

export const ContentTitle = styled.h2`
  color: #f8f7ff;
  font-size: 48px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 64px;
  margin: 0 0 32px;
  text-align: center;
  width: 100%;
`;
ContentTitle.displayName = 'ContentTitle';

export const Logos = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & img {
    margin: 56px 77px 0 0;
  }
  & img:last-child {
    margin: 56px 0 0 0;
  }

  ${MOBILE_RESOLUTION} {
    justify-content: space-around;

    & img {
      margin: 56px 16px 0;
    }
  }
`;
Logos.displayName = 'Logos';
