import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { LAYOUT } from '../../constants';

const { MOBILE_RESOLUTION } = LAYOUT;

export const BlogLink = styled(Link)`
  align-items: center;
  background: none;
  border: 0;
  border-radius: 8px;
  color: #f8f7ff;
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.04em;
  line-height: 24px;
  margin: 0 4px 0 0;
  text-decoration: none;
  width: 200px;

  ${MOBILE_RESOLUTION} {
    margin: 16px 0;
  }
`;
BlogLink.displayName = 'BlogLink';

export const Contact = styled.a`
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
`;
Contact.displayName = 'Contact';

export const Container = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-around;
  height: 88px;
  width: 100%;
  z-index: 3;

  ${MOBILE_RESOLUTION} {
    justify-content: space-between;
    padding: 0 16px;
    width: calc(100% - 32px);
  }
`;
Container.displayName = 'Container';

export const FollowContainer = styled.div`
  margin-bottom: 40px;

  & a:first-child {
    margin-right: 24px;
  }
`;
FollowContainer.displayName = 'FollowContainer';

export const Logo = styled.img`
  ${MOBILE_RESOLUTION} {
    width: 205px;
  }
`;
Logo.displayName = 'Logo';

export const MenuContainer = styled.aside`
  align-items: center;
  background-color: #0f0e17;
  bottom: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  width: ${({ visible }) => (visible ? '100%' : '0')};
  z-index: 2;
`;
MenuContainer.displayName = 'MenuContainer';

export const MenuIcon = styled.img`
  cursor: pointer;
  display: none;
  z-index: 3;

  ${MOBILE_RESOLUTION} {
    display: flex;
  }
`;
MenuIcon.displayName = 'MenuIcon';

export const Navegation = styled.nav`
  display: flex;

  ${MOBILE_RESOLUTION} {
    display: none;
  }
`;
Navegation.displayName = 'Navegation';

export const NavegationMobile = styled.nav`
  display: none;

  ${MOBILE_RESOLUTION} {
    display: flex;
    flex: 1;
    margin-top: 120px;
    flex-direction: column;
  }
`;
Navegation.displayName = 'Navegation';

export const TryNowLink = styled.button`
  align-items: center;
  background: #675ce7;
  border: 0;
  border-radius: 8px;
  color: #f8f7ff;
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  letter-spacing: 0.04em;
  line-height: 24px;
  text-decoration: none;
  width: 200px;

  ${MOBILE_RESOLUTION} {
    margin: 16px 0;
  }
`;
TryNowLink.displayName = 'TryNowLink';

export const TitleLink = styled(Link)`
  text-decoration: none;
`;
TitleLink.displayName = 'TitleLink';
