import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { LAYOUT } from '../../constants';

const { MOBILE_RESOLUTION } = LAYOUT;

export const Contact = styled.a`
  margin-right: 56px;

  &:last-child {
    margin-right: 0;
  }

  ${MOBILE_RESOLUTION} {
    margin-right: 24px;
  }
`;
Contact.displayName = 'Contact';

export const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 120px 0px;

  ${MOBILE_RESOLUTION} {
    padding: 80px 24px;
  }
`;
Container.displayName = 'Container';

export const Copyright = styled.p`
  color: #8894a4;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.02em;
  line-height: 16px;
  margin: 24px 0 0 0;

  & a {
    color: #675ce7;
    text-decoration: none;
  }
`;
Copyright.displayName = 'Copyright';

export const Legal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 56px 0px;

  ${MOBILE_RESOLUTION} {
    flex-direction: column;
  }
`;
Legal.displayName = 'Legal';

export const LinkItem = styled(Link)`
  color: #8894a4;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 24px;
  margin: 0;
  margin-right: 56px;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }

  ${MOBILE_RESOLUTION} {
    margin-right: 0;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
LinkItem.displayName = 'LinkItem';

export const Social = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin: 0 0 56px 0px;
`;
Social.displayName = 'Social';
