import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';

import { getArticles } from '../../constants';
import { AnalyticsProvider } from '../../providers';
import { Footer, Header } from '../../components';

import { ArticlesContent, Section, Subtitle, Title, TitleSection, Wrapper } from './styles';

function Article() {
  const { pathname } = useLocation();
  const { articleId } = useParams();
  const ARTICLES = getArticles();
  const article = ARTICLES.find((article) => article.slug === articleId);

  useEffect(() => {
    AnalyticsProvider.hit(pathname);
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>{article?.title}</title>
        <meta name="description" content={article?.description} />
      </Helmet>
      <Header />
      <Wrapper>
        <Section>
          <TitleSection>
            <Title>{article?.title}</Title>
            <Subtitle>{article.description}</Subtitle>
          </TitleSection>
          <ArticlesContent>{article.content}</ArticlesContent>
        </Section>
      </Wrapper>
      <Footer />
    </>
  );
}

export default Article;
