import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { useLocation } from 'react-router-dom';

import { getArticles } from '../../constants';
import { AnalyticsProvider } from '../../providers';
import { CardArticle, Footer, Header } from '../../components';

import { ArticlesSection, ArticlesWrapper, Section, Subtitle, Title, TitleSection, Wrapper } from './styles';

function Blog() {
  const { pathname } = useLocation();
  const ARTICLES = getArticles();
  const articlesToDisplay = ARTICLES.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)).slice(0, 6);

  useEffect(() => {
    AnalyticsProvider.hit('/blog');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>{i18n.t('screens.blog.metaTitle')}</title>
        <meta name="description" content={i18n.t('screens.blog.metaDescription')} />
      </Helmet>
      <Header />
      <Wrapper>
        <Section>
          <TitleSection>
            <Title>{i18n.t('screens.blog.title')}</Title>
            <Subtitle>{i18n.t('screens.blog.subtitle')}</Subtitle>
          </TitleSection>
        </Section>
        <ArticlesWrapper>
          <ArticlesSection>
            {articlesToDisplay.map((article) => (
              <CardArticle article={article} key={article.slug} />
            ))}
          </ArticlesSection>
        </ArticlesWrapper>
      </Wrapper>
      <Footer />
    </>
  );
}

export default Blog;
