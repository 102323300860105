import styled from 'styled-components';

import { LAYOUT } from '../../constants';

const { MOBILE_RESOLUTION, TABLET_RESOLUTION } = LAYOUT; // TODO work better on a tablet

export const Card = styled.div`
  align-items: flex-start;
  background: #1c1d1f;
  border: 1px solid #3e3d4a;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 264px;
  padding: 16px;
  width: 312px;

  ${({ first }) =>
    first &&
    `
  margin-right: 24px;
  `}

  ${MOBILE_RESOLUTION} {
    ${({ first }) =>
      first &&
      `
margin-bottom: 24px;
margin-right: 0;
`}
  }
`;
Card.displayName = 'Card';

export const Column = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  padding: 0px;

  ${MOBILE_RESOLUTION} {
    flex-direction: column;
  }
`;
Column.displayName = 'Column';

export const ColumnCta = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  padding: 0px;
`;
ColumnCta.displayName = 'ColumnCta';

export const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 120px 0px;

  ${MOBILE_RESOLUTION} {
    padding: 80px 24px;
  }
`;
Container.displayName = 'Container';

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;

  ${TABLET_RESOLUTION} {
    flex-direction: column;
  }
`;
Content.displayName = 'Content';

export const ContentTitle = styled.h2`
  color: #f8f7ff;
  font-size: 48px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 64px;
  margin: 0 136px 0 0;
  width: 312px;

  ${MOBILE_RESOLUTION} {
    margin: 32px 0px;
  }
`;
ContentTitle.displayName = 'ContentTitle';

export const CtaLink = styled.button`
  background: transparent;
  border: 0;
  color: #675ce7;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 24px;
  margin: 0px 13px;
  text-decoration: none;
`;
CtaLink.displayName = 'CtaLink';

export const Description = styled.p`
  color: #8894a4;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.02em;
  line-height: 24px;
  margin-bottom: 8px;
  margin-top: 0;

  & strong {
    color: #e7c05c;
    font-weight: normal;
  }
`;
Description.displayName = 'Description';

export const OverlaysContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding: 0px;
`;
OverlaysContainer.displayName = 'OverlaysContainer';

export const Title = styled.p`
  color: #f8f7ff;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 40px;
  margin-bottom: 8px;
  margin-top: 4px;
`;
Title.displayName = 'Title';
