import React from 'react';
import i18n from 'i18n-js';

import { AnalyticsProvider } from '../../providers';
import { getAppUrl } from '../../helpers';

import { Container, Description, SignUp, Title } from './styles';

function Main() {
  const onClickSignup = () => {
    const url = getAppUrl('dashboard');
    window.open(url, 'blank');
    AnalyticsProvider.event('CLICK_SIGNUP');
  };

  return (
    <Container>
      <Title>
        <strong>{i18n.t('screens.home.main.title')}</strong>{' '}
        {i18n.t('screens.home.main.title1')}
      </Title>
      <Description>{i18n.t('screens.home.main.description')}</Description>
      <SignUp onClick={onClickSignup}>
        {i18n.t('screens.home.main.signup')}
      </SignUp>
    </Container>
  );
}

export default Main;
