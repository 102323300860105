import React from 'react';
import i18n from 'i18n-js';

import addImg from '../../assets/icons/add.svg';
import campaignImg from '../../assets/icons/campaign.svg';
import revenueImg from '../../assets/icons/money.svg';

import { AnalyticsProvider } from '../../providers';
import { getAppUrl } from '../../helpers';

import {
  Container,
  Description,
  Details,
  DetailsDescription,
  DetailsTitle,
  EarnMoreButton,
  Title,
} from './styles';

function Monetization() {
  const onClickEarnMore = () => {
    const url = getAppUrl('revenue');
    window.open(url, 'blank');
    AnalyticsProvider.event('EARNING_NOW');
  };

  return (
    <Container id="monetization">
      <Title>{i18n.t('screens.home.monetization.title')}</Title>
      <Description>
        <Details>
          <img src={campaignImg} alt="campaign" />
          <DetailsTitle>
            {i18n.t('screens.home.monetization.title1')}
          </DetailsTitle>
          <DetailsDescription>
            {i18n.t('screens.home.monetization.description1')}
          </DetailsDescription>
        </Details>
        <Details>
          <img src={addImg} alt="add to stream" />
          <DetailsTitle>
            {i18n.t('screens.home.monetization.title2')}
          </DetailsTitle>
          <DetailsDescription>
            {i18n.t('screens.home.monetization.description2')}
          </DetailsDescription>
        </Details>
        <Details>
          <img src={revenueImg} alt="revenue" />
          <DetailsTitle>
            {i18n.t('screens.home.monetization.title3')}
          </DetailsTitle>
          <DetailsDescription>
            {i18n.t('screens.home.monetization.description3')}
          </DetailsDescription>
        </Details>
      </Description>
      <EarnMoreButton onClick={onClickEarnMore}>
        {i18n.t('screens.home.monetization.cta')}
      </EarnMoreButton>
    </Container>
  );
}

export default Monetization;
