import i18n from 'i18n-js';

export const articles = () => [
  {
    // The unique slug to use in the URL. It's also used to generate the canonical URL.
    slug: i18n.t('screens.articles.soccer-scoreboard.slug'),
    // The title to display in the article page (h1). Less than 60 characters. It's also used to generate the meta title.
    title: i18n.t('screens.articles.soccer-scoreboard.title'),
    // The description of the article to display in the article page. Up to 160 characters. It's also used to generate the meta description.
    description: i18n.t('screens.articles.soccer-scoreboard.description'),
    // The date of the article. It's used to generate the meta date.
    publishedAt: '2024-06-25',
    image: {
      // The image to display in <CardArticle /> components.
      src: 'https://miro.medium.com/v2/resize:fit:6912/1*gu3wHxERa3qjhNfuAGF6tg.png',
      // The relative URL of the same image to use in the Open Graph meta tags & the Schema Markup JSON-LD.
      alt: i18n.t('screens.articles.soccer-scoreboard.image-alt'),
    },
    // The actual content of the article that will be shown under the <h1> title in the article page.
    content: (
      <>
        <center>
          <img
            src="https://miro.medium.com/v2/resize:fit:6912/1*gu3wHxERa3qjhNfuAGF6tg.png"
            alt={i18n.t('screens.articles.soccer-scoreboard.image-alt')}
            width={1920}
            height={1080}
            style={{ width: '100%', height: 'auto', aspectRatio: 'auto 1920 / 1080' }}
          />
        </center>
        <p>{i18n.t('screens.articles.soccer-scoreboard.content.intro1')}</p>
        <section>
          <h2>{i18n.t('screens.articles.soccer-scoreboard.content.section1-title')}</h2>
          <p>
            1.- <b>{i18n.t('screens.articles.soccer-scoreboard.content.section1-point1-title')}</b>
            {i18n.t('screens.articles.soccer-scoreboard.content.section1-point1-content')}
            <a href="https://app.overlayon.com/template/soccer" rel="noreferrer" target="_blank">
              {i18n.t('screens.articles.soccer-scoreboard.content.section1-point1-link')}
            </a>
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*Jzeu4-MKvaLTDJhvwn6ezw.png"
              alt={i18n.t('screens.articles.soccer-scoreboard.content.section1-point1-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
          <p>
            2.- <b>{i18n.t('screens.articles.soccer-scoreboard.content.section1-point2-title')}</b>
            {i18n.t('screens.articles.soccer-scoreboard.content.section1-point2-content')}
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*dRrFRuT0hMNIi7p6yleCYg.png"
              alt={i18n.t('screens.articles.soccer-scoreboard.content.section1-point2-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
          <p>
            3.- <b>{i18n.t('screens.articles.soccer-scoreboard.content.section1-point3-title')}</b>
            {i18n.t('screens.articles.soccer-scoreboard.content.section1-point3-content')}
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*erQams4NS7LC_PjH6e2trg.png"
              alt={i18n.t('screens.articles.soccer-scoreboard.content.section1-point3-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
          <p>
            4.- <b>{i18n.t('screens.articles.soccer-scoreboard.content.section1-point4-title')}</b>
            {i18n.t('screens.articles.soccer-scoreboard.content.section1-point4-content')}
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*t_osezwBnm_TZOqdWC80Gw.png"
              alt={i18n.t('screens.articles.soccer-scoreboard.content.section1-point4-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
        </section>
        <section>
          <h2>{i18n.t('screens.articles.soccer-scoreboard.content.section2-title')}</h2>
          <p>
            1.- <b>{i18n.t('screens.articles.soccer-scoreboard.content.section2-point1-title')}</b>
            {i18n.t('screens.articles.soccer-scoreboard.content.section2-point1-content')}
            <a href="https://obsproject.com/es/download" rel="noreferrer" target="_blank">
              {i18n.t('screens.articles.soccer-scoreboard.content.section2-point1-link')}
            </a>
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*5nm62Y_oQrGBFumoy7PHUQ.png"
              alt={i18n.t('screens.articles.soccer-scoreboard.content.section2-point1-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
          <p>
            2.- <b>{i18n.t('screens.articles.soccer-scoreboard.content.section2-point2-title')}</b>
            {i18n.t('screens.articles.soccer-scoreboard.content.section2-point2-content')}
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*RZHUVJw-Wx1pyS-f2djLKg.png"
              alt={i18n.t('screens.articles.soccer-scoreboard.content.section2-point2-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
          <p>
            3.- <b>{i18n.t('screens.articles.soccer-scoreboard.content.section2-point3-title')}</b>
            {i18n.t('screens.articles.soccer-scoreboard.content.section2-point3-content')}
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*HBqbYCqdKso4zcEviEi6og.png"
              alt={i18n.t('screens.articles.soccer-scoreboard.content.section2-point3-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
          <p>
            4.- <b>{i18n.t('screens.articles.soccer-scoreboard.content.section2-point4-title')}</b>
            {i18n.t('screens.articles.soccer-scoreboard.content.section2-point4-content')}
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*saCmv3VqlO_9uax8W6vekQ.png"
              alt={i18n.t('screens.articles.soccer-scoreboard.content.section2-point3-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
        </section>
        <section>
          <h2>{i18n.t('screens.articles.soccer-scoreboard.content.outro-title')}</h2>
          <p>{i18n.t('screens.articles.soccer-scoreboard.content.outro1')}</p>
          <p>{i18n.t('screens.articles.soccer-scoreboard.content.outro2')}</p>
        </section>
      </>
    ),
  },
  {
    // The unique slug to use in the URL. It's also used to generate the canonical URL.
    slug: i18n.t('screens.articles.countdown-timer.slug'),
    // The title to display in the article page (h1). Less than 60 characters. It's also used to generate the meta title.
    title: i18n.t('screens.articles.countdown-timer.title'),
    // The description of the article to display in the article page. Up to 160 characters. It's also used to generate the meta description.
    description: i18n.t('screens.articles.countdown-timer.description'),
    // The date of the article. It's used to generate the meta date.
    publishedAt: '2024-06-15',
    image: {
      // The image to display in <CardArticle /> components.
      src: 'https://miro.medium.com/v2/resize:fit:3840/format:webp/1*t2Yq64qBH7cRidYbkfwGxw.png',
      // The relative URL of the same image to use in the Open Graph meta tags & the Schema Markup JSON-LD.
      alt: i18n.t('screens.articles.countdown-timer.image-alt'),
    },
    // The actual content of the article that will be shown under the <h1> title in the article page.
    content: (
      <>
        <center>
          <img
            src="https://miro.medium.com/v2/resize:fit:3840/format:webp/1*t2Yq64qBH7cRidYbkfwGxw.png"
            alt={i18n.t('screens.articles.countdown-timer.image-alt')}
            width={1920}
            height={1080}
            style={{ width: '100%', height: 'auto', aspectRatio: 'auto 1920 / 1080' }}
          />
        </center>
        <p>{i18n.t('screens.articles.countdown-timer.content.intro1')}</p>
        <p>{i18n.t('screens.articles.countdown-timer.content.intro2')}</p>
        <section>
          <h2>{i18n.t('screens.articles.countdown-timer.content.section1-title')}</h2>
          <p>
            1.- <b>{i18n.t('screens.articles.countdown-timer.content.section1-point1-title')}</b>
            {i18n.t('screens.articles.countdown-timer.content.section1-point1-content')}
            <a href="https://app.overlayon.com/template/countdown" rel="noreferrer" target="_blank">
              {i18n.t('screens.articles.countdown-timer.content.section1-point1-link')}
            </a>
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*RCTmfjnW0Uyj3_qsWDZ_MQ.png"
              alt={i18n.t('screens.articles.countdown-timer.content.section1-point1-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
          <p>
            2.- <b>{i18n.t('screens.articles.countdown-timer.content.section1-point2-title')}</b>
            {i18n.t('screens.articles.countdown-timer.content.section1-point2-content')}
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*s3dikT3CfbxBJxihjkeBiA.png"
              alt={i18n.t('screens.articles.countdown-timer.content.section1-point2-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
          <p>
            3.- <b>{i18n.t('screens.articles.countdown-timer.content.section1-point3-title')}</b>
            {i18n.t('screens.articles.countdown-timer.content.section1-point3-content')}
          </p>
          <p>
            4.- <b>{i18n.t('screens.articles.countdown-timer.content.section1-point4-title')}</b>
            {i18n.t('screens.articles.countdown-timer.content.section1-point4-content')}
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*Y1WHKm89QfUUlw6oBUAKIw.png"
              alt={i18n.t('screens.articles.countdown-timer.content.section1-point4-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
        </section>
        <section>
          <h2>{i18n.t('screens.articles.countdown-timer.content.section2-title')}</h2>
          <p>
            1.- <b>{i18n.t('screens.articles.countdown-timer.content.section2-point1-title')}</b>
            {i18n.t('screens.articles.countdown-timer.content.section2-point1-content')}
            <a href="https://obsproject.com/es/download" rel="noreferrer" target="_blank">
              {i18n.t('screens.articles.countdown-timer.content.section2-point1-link')}
            </a>
          </p>
          <p>
            2.- <b>{i18n.t('screens.articles.countdown-timer.content.section2-point2-title')}</b>
            {i18n.t('screens.articles.countdown-timer.content.section2-point2-content')}
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*ZAVgk3fwtpxgIPRwmQ_zNg.png"
              alt={i18n.t('screens.articles.countdown-timer.content.section2-point2-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
          <p>
            3.- <b>{i18n.t('screens.articles.countdown-timer.content.section2-point3-title')}</b>
            {i18n.t('screens.articles.countdown-timer.content.section2-point3-content')}
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*8-EbZfUrzdrGbUeYi4E50Q.png"
              alt={i18n.t('screens.articles.countdown-timer.content.section2-point3-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
          <p>
            4.- <b>{i18n.t('screens.articles.countdown-timer.content.section2-point4-title')}</b>
            {i18n.t('screens.articles.countdown-timer.content.section2-point4-content')}
          </p>
          <center>
            <img
              src="https://miro.medium.com/v2/resize:fit:1400/1*O9DO0Brwzpr0vSLtfG0xww.png"
              alt={i18n.t('screens.articles.countdown-timer.content.section2-point3-image-alt')}
              width={700}
              height={394}
              loading="lazy"
            />
          </center>
        </section>
        <section>
          <p>{i18n.t('screens.articles.countdown-timer.content.outro1')}</p>
          <h2>{i18n.t('screens.articles.countdown-timer.content.outro-title')}</h2>
          <p>{i18n.t('screens.articles.countdown-timer.content.outro2')}</p>
          <p>{i18n.t('screens.articles.countdown-timer.content.outro3')}</p>
        </section>
      </>
    ),
  },
];

export default articles;
